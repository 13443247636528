<template>
  <div>
    <app-breadcrumb
      v-if="permission.mitraAdd"
      action="Tambah Mitra Baru"
      link="/checkmitra"
    />
    <div
      v-if="loading"
      key="1"
      class="d-flex align-items-center justify-content-center"
      style="height: 60vh"
    >
      <b-spinner variant="primary" />
    </div>
    <b-card
      v-else
      no-body
    >
      <div>
        <b-button
          v-b-toggle.sidebar-right
          variant="success"
          class="m-2"
        >
          <feather-icon
            icon="FilterIcon"
            size="14"
          />
          <span class="ml-1">Filter</span>
        </b-button>

        <b-sidebar
          id="sidebar-right"
          title="Opsi"
          bg-variant="light"
          right
          shadow
        >
          <div class="px-2 py-1">
            <b-col
              class="d-flex align-content-center justify-content-start mb-1 mb-md-0"
            >
              <label>Tampilkan</label>
              <v-select
                v-model="perPage"
                dir="ltr"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
                @close="getMitra"
              />
              <label>data</label>
            </b-col>
            <b-col class="mt-1">
              <b-overlay
                :show="showOverlay"
                rounded
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  v-if="permission.mitraSemua"
                  :disabled="showOverlay"
                  variant="gradient-primary"
                  block
                  @click="getAllMitra()"
                >
                  <span class="align-middle">Tampilkan semua mitra</span>
                </b-button>
              </b-overlay>
            </b-col>
            <b-col class="mt-1">
              <b-button
                v-if="permission.mitraDownload"
                variant="gradient-success"
                class="mr-1"
                block
                @click="openBottomSheetDownload()"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="15"
                />
                <span class="ml-1 align-middle">Download</span>
              </b-button>
            </b-col>
            <b-col class="mt-1">
              <b-button-group>
                <b-button
                  :variant="toggleMaps ? 'outline-primary' : 'primary'"
                  @click="toggleMaps=false"
                >
                  <span class="align">List</span>
                </b-button>
                <b-button
                  :variant="toggleMaps ? 'primary' : 'outline-primary'"
                  @click="toggleMaps=true"
                >
                  Maps
                </b-button>
              </b-button-group>
            </b-col>
            <b-col class="mt-1">
              <v-select
                v-model="statusFilter"
                dir="ltr"
                label="label"
                :clearable="false"
                :options="statusOptions"
                class="Invoice-filter-select"
                placeholder="Filter Status"
                @input="getMitra()"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-col>
            <b-col class="mt-1">
              <v-select
                v-model="layananFilter"
                dir="ltr"
                label="label"
                :clearable="false"
                :options="layananOptions"
                class="Invoice-filter-select"
                placeholder="Filter Layanan"
                @option:selected="applyFilterLayanan()"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-col>
            <b-col
              v-if="permission.mitraBlock"
              class="mt-1"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="gradient-success"
                  block
                  class="d-flex justify-content-between p-50"
                  @click="findMitra()"
                >
                  <p
                    class="m-0"
                  >
                    <span class="align-middle">Cari Mitra</span>
                  </p>
                  <feather-icon
                    icon="SearchIcon"
                    size="16"
                    class="mr-25"
                    style="padding-top: 2px"
                  />
                </b-button>
              </div>
            </b-col>
            <b-col class="mt-1">
              <b-dropdown
                v-if="permission.statusOrderUpdate"
                block
                right
                text="Aktifkan status order"
                variant="gradient-success"
              >
                <b-dropdown-item
                  @click="statusOrderAktif()"
                >
                  Orderan aktif
                </b-dropdown-item>
                <b-dropdown-item
                  @click="statusOrderNonAktif()"
                >
                  Orderan non aktif
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </div>
        </b-sidebar>
        <vue-bottom-sheet
          ref="searchMitraBottomSheet"
          class="p-0"
          :rounded="false"
        >
          <div class="bottom-sheet">
            <div class="d-flex justify-content-between align-item-center">
              <h4>Cari Mitra</h4>
              <feather-icon
                icon="XIcon"
                size="28"
                class="text-primary"
                @click="closeBottomSheetFindMitra()"
              />
            </div>
            <ais-instant-search
              :search-client="searchClient"
              index-name="mitra"
            >
              <ais-search-box
                placeholder="Cari mitra (bisa gunakan nama atau nomor hp)"
                :class-names="{
                  'ais-SearchBox-input': 'form-control',
                  'ais-SearchBox-submit': 'd-none',
                  'ais-SearchBox-reset': 'd-none',
                }"
                class="mt-1"
              >
                <div
                  slot="submit-icon"
                >
                  Cari
                </div>
              </ais-search-box>
              <ais-hits
                :class-names="{
                  'ais-Hits-list' : 'hitsList',
                }"
              >
                <div
                  slot="item"
                  slot-scope="{ item }"
                  class="mt-1"
                >
                  <b-card
                    border-variant="primary"
                    class="m-0"
                  >
                    <div class="card-body d-flex justify-content-between p-0">
                      <div class="d-inline-block">
                        <h6
                          class="text-primary font-weight-bolder"
                          style="cursor: pointer"
                          @click="detailMitra(item.objectID)"
                        >
                          {{ item.nama }}
                        </h6>
                        <p
                          class="text-secondary m-0"
                        >
                          {{ item.no_hp }}
                        </p>
                      </div>
                      <b-dropdown
                        variant="link"
                        toggle-class="p-0"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          v-if="permission.mitraUpdate"
                          @click="$router.push({ name: 'EditMitra', params: {id: item.no_hp} })"
                        >
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">Ubah Data Mitra</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="permission.mitraUpdate"
                          @click="setLeader(item.no_hp)"
                        >
                          <feather-icon icon="UserIcon" />
                          <span class="align-middle ml-50">Set Leader</span>
                        </b-dropdown-item>
                        <b-dropdown-divider />
                        <b-dropdown-item
                          v-if="permission.mitraDelete"
                          @click="hapusMitra(item.no_hp)"
                        >
                          <feather-icon
                            class="text-danger"
                            icon="CopyIcon"
                          />
                          <span class="align-middle ml-50 text-danger">Hapus Data Mitra</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </b-card>
                </div>
              </ais-hits>
            </ais-instant-search>
          </div>
        </vue-bottom-sheet>
      </div>
      <div>
        <vue-bottom-sheet
          ref="downloadBottomSheet"
          class="p-1"
          :rounded="false"
        >
          <div class="bottom-sheet-download">
            <div class="d-flex justify-content-between align-items-center">
              <h5>Download mitra</h5>
              <feather-icon
                icon="XIcon"
                size="28"
                class="text-primary"
                @click="closeBottomSheetDownload()"
              />
            </div>
            <div class="mt-1">
              <v-select
                v-model="statusAktif"
                label="label"
                :options="selectedStatusAktif"
                placeholder="Pilih Status Aktif"
              />
              <b-button
                variant="success"
                class="mt-2"
                block
                @click="downloadMitra()"
              >
                Download Mitra
              </b-button>
            </div>
          </div>
        </vue-bottom-sheet>
      </div>

      <b-overlay
        :show="showOverlay"
        rounded="sm"
        class="d-flex flex-column"
      >
        <b-table
          v-if="!toggleMaps"
          ref="refMitraListTable"
          :items="filteredMitra"
          responsive
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Data masih belum tersedia"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
          <template #cell(nama)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  badge
                  :src="data.item.fotoProfil"
                  :text="avatarText(data.item.nama)"
                  :variant="`light-primary`"
                />
              </template>
              <router-link
                class="font-weight-bold d-block text-nowrap"
                :to="{ name: 'DetailMitra', params: {id: data.item.no_hp} }"
              >
                {{ data.item.nama }}
              </router-link>
              <small class="text-muted">Mitra {{ vertikalMitra(data.item.layanan[0].vertikal) }}</small>
            </b-media>
          </template>
          <template #cell(no_hp)="data">
            <p
              class="m-0"
            >
              {{ data.value }}
            </p>
          </template>
          <template #cell(layanan)="data">
            <b-badge
              v-for="item in data.item.layanan"
              :key="item.vertikal"
              v-b-popover.hover.top="`${item.nama_leader}`"
              class="text-capitalize badge-mitra"
              :class="{'bg-primary' : item.leader !== null && item.leader !== ''}"
            >
              {{ vertikalMitra(item.vertikal) }} {{ areaMitra(item.area) }}
            </b-badge>
          </template>
          <template #cell(status_order)="data">
            <b-badge :variant="displayOrderStatus(data.value).variant">
              {{ displayOrderStatus(data.value).display }}
            </b-badge>
          </template>
          <template #cell(status_aktif)="data">
            <b-badge :variant="statusMitra(data.value).variant">
              {{ statusMitra(data.value).display }}
            </b-badge>
          </template>
          <template #cell(saldo)="data">
            <b-badge :variant="data.value < 20000 ? 'light-danger' : 'light-primary'">
              {{ data.value }}
            </b-badge>
          </template>
          <template
            v-if="permission.mitraUpdate || permission.mitraDelete"
            #cell(aksi)="data"
          >
            <div class="text-nowrap">
              <!-- Dropdown -->
              <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="permission.mitraUpdate"
                  @click="$router.push({ name: 'EditMitra', params: {id: data.item.no_hp} })"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Ubah Data Mitra</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="permission.mitraUpdate"
                  @click="setLeader(data.item.no_hp)"
                >
                  <feather-icon icon="UserIcon" />
                  <span class="align-middle ml-50">Set Leader</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.status_aktif !== 4 && permission.mitraBlock"
                  @click="aktifkanMitra(data.item.no_hp)"
                >
                  <feather-icon
                    class="text-primary"
                    icon="CopyIcon"
                  />
                  <span class="align-middle ml-50 text-primary">Aktifkan Mitra</span>
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item
                  v-if="permission.mitraDelete"
                  @click="hapusMitra(data.item.no_hp)"
                >
                  <feather-icon
                    class="text-danger"
                    icon="CopyIcon"
                  />
                  <span class="align-middle ml-50 text-danger">Hapus Data Mitra</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>

        <div
          v-if="loadMorebtn"
          class="mx-auto mb-2"
        >
          <button
            class="btn btn-primary"
            @click="loadMore"
          >
            <feather-icon icon="ChevronDownIcon" />
            Lihat lebih banyak
          </button>
        </div>
        <leaflet-mitra
          v-if="toggleMaps"
          :mitra="listMitra"
        />
      </b-overlay>
    </b-card>

    <vue-bottom-sheet
      ref="myBottomSheet"
      class="p-1"
      :rounded="false"
      :click-to-close="false"
    >
      <div class="bottom-sheet">
        <div class="d-flex justify-content-between mb-1 align-items-center">
          <h4>Set Leader</h4>
          <feather-icon
            icon="XIcon"
            size="28"
            class="text-primary"
            @click="closeBottomSheet"
          />
        </div>
        <hr>
        <h5 class="mb-1">
          Mitra : {{ currentMitra.nama }}
        </h5>
        <hr>
        <v-select
          v-model="updateLayanan"
          label="label"
          :clearable="false"
          :options="layananMitra"
          class="Invoice-filter-select mb-1"
          placeholder="Layanan"
          @input="getLeader()"
        />
        <v-select
          v-if="leaderList.length"
          v-model="updateLeader"
          label="label"
          :clearable="false"
          :options="leaderList"
          class="Invoice-filter-select mb-1"
          placeholder="Pilih Leader"
        />
        <b-button
          v-if="leaderList.length"
          block
          variant="primary"
          @click="prosesUpdateLeader()"
        >
          Set Leader
        </b-button>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BButton, BOverlay,
  BBadge, BDropdown, BDropdownItem, BDropdownDivider, BSpinner, VBPopover,
  BButtonGroup, BSidebar, VBToggle,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import algoliasearch from 'algoliasearch/lite'
import { AisInstantSearch, AisSearchBox } from 'vue-instantsearch'
import fileDownload from 'js-file-download'
import firebase from 'firebase/app'
import { db } from '@/firebase'
import permission from '@/permission'
import regionJson from '@/db/region.json'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import LeafletMitra from './LeafletMitra.vue'

export default {
  /* eslint-disable vue/no-unused-components */
  /* eslint-disable vue/no-unused-vars */
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BButton,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    AppBreadcrumb,
    BDropdownDivider,
    BSpinner,
    BOverlay,
    vSelect,
    VueBottomSheet,
    VBPopover,
    algoliasearch,
    AisInstantSearch,
    AisSearchBox,
    BButtonGroup,
    LeafletMitra,
    BSidebar,
    FeatherIcon,
  },
  directives: {
    'b-popover': VBPopover,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      searchClient: algoliasearch(
        '4LASHL8CBV',
        '0f873fc12e5ca3f430fbe1014a65c384',
      ),
      toggleMaps: false,
      leaderData: '',
      loading: true,
      showOverlay: true,
      permission: permission.access,
      perPage: 40,
      pageAll: 100,
      perPageOptions: [40, 80, 120],
      statusFilter: { value: 0, label: 'Semua Status' },
      statusOptions: [
        { value: 0, label: 'Semua Status' },
        { value: 1, label: 'Sedang Diproses' },
        { value: 2, label: 'Sedang Diverifikasi' },
        { value: 3, label: 'Ditolak' },
        { value: 4, label: 'Aktif' },
        { value: 5, label: 'Saldo Kurang' },
        { value: 6, label: 'Harus Konseling' },
        { value: 7, label: 'Perlu Retraining' },
        { value: 8, label: 'Dinonaktifkan' },
        { value: 9, label: 'Diblokir' },
      ],
      layananFilter: { value: '0', label: 'Semua Layanan' },
      layananOptions: [
        { value: '0', label: 'Semua Layanan' },
        { value: 'vl01', label: 'Akupunktur' },
        { value: 'vl02', label: 'Auto' },
        { value: 'vl03', label: 'Barber' },
        { value: 'vl04', label: 'Bekam' },
        { value: 'vl05', label: 'Beauty' },
        { value: 'vl06', label: 'Cleaning' },
        { value: 'vl07', label: 'Massage' },
        { value: 'vl08', label: 'Cuci AC' },
      ],
      isSortDirDesc: false,
      tableColumns: [
        { key: 'nama', label: 'Mitra', sortable: true },
        { key: 'no_hp', label: 'Nomor HP', sortable: true },
        { key: 'layanan', sortable: false },
        { key: 'status_order', label: 'status order', sortable: false },
        { key: 'status_aktif', label: 'status', sortable: false },
        { key: 'saldo', label: 'saldo', sortable: false },
        { key: 'aksi', label: '' },
      ],
      listMitra: [],
      filteredMitra: [],
      totalMitra: 0,
      avatarText,
      sortBy: 'nama',
      lastVisible: null,
      loadMorebtn: true,
      searchQuery: '',
      region: regionJson,
      currentMitra: '',
      layananMitra: [],
      leaderList: [],
      updateLayanan: '',
      updateLeader: '',
      indexSearch: [],
      statusAktif: '',
      selectedStatusAktif: [
        { value: 1, label: 'Sedang Diproses' },
        { value: 2, label: 'Sedang Diverifikasi' },
        { value: 3, label: 'Ditolak' },
        { value: 4, label: 'Aktif' },
        { value: 5, label: 'Saldo Kurang' },
        { value: 6, label: 'Harus Konseling' },
        { value: 7, label: 'Perlu Retraining' },
        { value: 8, label: 'Dinonaktifkan' },
        { value: 9, label: 'Diblokir' },
      ],
    }
  },
  created() {
    this.leaderData = JSON.parse(localStorage.getItem('userData'))

    if (this.leaderData.permission.mitra.area[0] !== 'all') {
      this.leaderData.permission.mitra.area.forEach(area => {
        const layanan = area.split('-')[0]
        const region = area.split('-')[1]

        this.indexSearch.push({
          area: region,
          leader: this.leaderData.leader_id,
          vertikal: layanan,
        })
      })
    }

    this.getMitra()
  },
  methods: {
    async getMitra() {
      this.showOverlay = true
      this.loadMorebtn = true
      const options = {
        url: 'https://us-central1-mecare-life.cloudfunctions.net/getMitra',
        method: 'POST',
        data: {
          limit: this.perPage <= 40 ? 40 : this.perPage,
          status: this.statusFilter.value,
          lastVisible: null,
          regionLeader: this.indexSearch,
        },
      }

      this.loading = false

      await this.$axios(options).then(res => {
        this.lastVisible = res.data.lastVisible
        this.listMitra = [...new Set(res.data.mitra)]
        this.filteredMitra = [...new Set(res.data.mitra)]

        if (this.layananFilter.value !== '0') {
          this.applyFilterLayanan()
        }

        if (res.data.mitra.length === 0) {
          this.loadMorebtn = false
        }
      }).catch(() => {
        this.loading = false
        this.showOverlay = false
      })

      this.showOverlay = false
    },
    async getAllMitra() {
      this.showOverlay = false

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Fitur tampilkan semua mitra sedang dalam kondisi di nonaktifkan',
          icon: 'AlertOctagonIcon',
          variant: 'warning',
        },
      })

      // const options = {
      //   url: 'https://us-central1-mecare-life.cloudfunctions.net/getAllMitra',
      //   method: 'POST',
      //   data: {
      //     status: this.statusFilter.value,
      //     lastVisible: null,
      //     limit: this.perPage <= 1000 ? 1000 : this.perPage,
      //     regionLeader: this.indexSearch,
      //   },
      // }

      // this.loading = false

      // await this.$axios(options).then(res => {
      //   this.lastVisible = res.data.lastVisible
      //   this.listMitra = [...new Set(res.data.mitra)]
      //   this.filteredMitra = [...new Set(res.data.mitra)]

      //   if (this.layananFilter.value !== '0') {
      //     this.applyFilterLayanan()
      //   }

      //   if (res.data.mitra.length === 0) {
      //     this.loadMorebtn = false
      //   }

      //   // console.log(res.data.lastVisibles)
      // }).catch(() => {
      //   this.loading = false
      //   this.showOverlay = false
      // })

      // this.showOverlay = false
    },
    loadMore() {
      if (this.listMitra.length === this.perPage) {
        this.perPage += 40
        this.getMitra()
      } else {
        this.loadMorebtn = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data sudah ditampilkan semua',
            icon: 'CheckIcon',
            variant: 'success',
            position: 'bottom',
          },
        })
      }
    },
    applyFilterLayanan() {
      /* eslint-disable arrow-body-style */
      this.showOverlay = true
      if (this.layananFilter.value === '0') {
        this.getMitra()
      } else {
        const filtered = this.listMitra.filter(item => {
          return item.layanan[0].vertikal === this.layananFilter.value
        })

        this.filteredMitra = [...new Set(filtered)]

        this.showOverlay = false
      }
    },

    vertikalMitra(vl) {
      if (vl) {
        const vertikalVariant = {
          vl01: 'Akupunktur',
          vl02: 'Auto',
          vl03: 'Barber',
          vl04: 'Bekam',
          vl05: 'Beauty',
          vl06: 'Cleaning',
          vl07: 'Massage',
          vl08: 'Cuci AC',
        }
        return vertikalVariant[vl]
      }

      return 'Layanan Belum Diisi'
    },
    displayOrderStatus(statusOrder) {
      if (statusOrder === true) return { variant: 'success', display: 'Orderan On' }
      if (statusOrder === false) return { variant: 'danger', display: 'Orderan Off' }
      return { variant: 'warning', display: 'tidak diketahui' }
    },
    statusMitra(status) {
      if (status === 1) return { variant: 'light-info', display: 'Sedang Diproses' }
      if (status === 2) return { variant: 'light-info', display: 'Sedang Diverifikasi' }
      if (status === 3) return { variant: 'light-danger', display: 'Ditolak' }
      if (status === 4) return { variant: 'light-primary', display: 'Aktif' }
      if (status === 5) return { variant: 'light-warning', display: 'Saldo Kurang' }
      if (status === 6) return { variant: 'light-warning', display: 'Harus Konseling' }
      if (status === 7) return { variant: 'light-warning', display: 'Perlu Retraining' }
      if (status === 8) return { variant: 'light-danger', display: 'Dinonaktifkan' }
      if (status === 9) return { variant: 'light-danger', display: 'Diblokir' }
      return { variant: 'light-secondary', display: 'tidak diketahui' }
    },

    hapusMitra(no) {
      this.$swal({
        title: 'Hapus data mitra?',
        text: 'Konfirmasi jika anda ingin menghapus data mitra',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          db.collection('mitra').doc(`${no}`).delete().then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil menghapus data mitra',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
          })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.value,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            })
        }
      })
    },
    aktifkanMitra(no) {
      this.$swal({
        title: 'Aktifkan mitra?',
        text: 'Konfirmasi jika anda ingin mengaktifkan mitra',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          db.collection('mitra').doc(no).set({
            status_aktif: 4,
            alasan_ditolak: firebase.firestore.FieldValue.delete(),
            terakhir_diedit: +new Date(),
          }, { merge: true }).then(() => {
            this.getMitra()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Mitra berhasil diaktifkan',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    areaMitra(area) {
      if (area) {
        const fromjson = regionJson.find(item => {
          return item.id === area
        })

        const region = fromjson.name.split(' ')

        region.shift()

        const kota = region.join(' ')

        return kota.toLowerCase()
      }

      return 'Kota Tidak Tersedia'
    },

    async setLeader(nohp) {
      await db.collection('mitra').doc(nohp).get().then(snap => {
        const arr = []
        let nomer = 0
        snap.data().layanan.forEach(el => {
          arr.push({
            label: `${this.vertikalMitra(el.vertikal)} - ${this.areaMitra(el.area).toUpperCase()}`,
            vertikal: el.vertikal,
            area: el.area,
            leader: el.leader,
            index: nomer,
          })

          nomer += 1
        })
        this.layananMitra = [...new Set(arr)]
        this.currentMitra = snap.data()
        this.$refs.myBottomSheet.open()
      })
    },
    getLeader() {
      db.collection('mecarehub').where('role', '==', 'leader').orderBy('nama', 'asc').get()
        .then(snapshot => {
          const arr = []
          snapshot.docs.forEach(doc => {
            const layananLeader = this.vertikalMitra(doc.data().access.mitra.area[0].split('-')[0])
            const areaLeader = this.areaMitra(doc.data().access.mitra.area[0].split('-')[1])
            arr.push({
              id: doc.id,
              label: `${doc.data().nama} - Leader ${layananLeader} ${areaLeader.toUpperCase()}`,
              area: doc.data().access.mitra.area[0].split('-')[1],
            })
          })
          this.leaderList = [...new Set(arr)]
        })
    },
    prosesUpdateLeader() {
      const layIndex = this.updateLayanan.index
      db.collection('mitra').doc(this.currentMitra.no_hp).update({
        layanan: firebase.firestore.FieldValue.arrayRemove({
          area: this.currentMitra.layanan[layIndex].area,
          leader: this.currentMitra.layanan[layIndex].leader,
          vertikal: this.currentMitra.layanan[layIndex].vertikal,
        }),
      }).then(() => {
        db.collection('mitra').doc(this.currentMitra.no_hp).update({
          layanan: firebase.firestore.FieldValue.arrayUnion({
            area: this.updateLeader.area,
            leader: this.updateLeader.id,
            vertikal: this.currentMitra.layanan[layIndex].vertikal,
          }),
        }).then(() => {
          this.closeBottomSheet()
          this.currentMitra = ''
          this.updateLeader = ''
          this.updateLayanan = ''
          this.getMitra()
        })
      })
    },
    statusOrderAktif() {
      this.$swal({
        title: 'Apakah anda yakin untuk mengaktifkan?',
        text: 'Konfirmasi jika anda sudah yakin',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Mohon ditunggu 1 menit',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.getMitra()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Mengaktifkan status order dibatalkan',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    statusOrderNonAktif() {
      this.$swal({
        title: 'Apakah anda yakin untuk mengaktifkan?',
        text: 'Konfirmasi jika anda sudah yakin',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Mohon ditunggu 1 menit',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.getMitra()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Mengaktifkan status order dibatalkan',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    detailMitra(noHp) {
      this.closeBottomSheet()
      this.$router.push({ name: 'DetailMitra', params: { id: noHp } })
    },
    findMitra() {
      this.$refs.searchMitraBottomSheet.open()
    },
    closeBottomSheetFindMitra() {
      this.$refs.searchMitraBottomSheet.close()
    },
    closeBottomSheet() {
      this.$refs.myBottomSheet.close()
    },
    openBottomSheetDownload() {
      this.$refs.downloadBottomSheet.open()
    },
    closeBottomSheetDownload() {
      this.$refs.downloadBottomSheet.close()
    },
    downloadMitra() {
      const options = {
        url: 'https://us-central1-mecare-life.cloudfunctions.net/downloadMitra',
        method: 'POST',
        data: {
          status_aktif: this.statusAktif.value,
        },
      }
      this.$axios(options).then(res => {
        fileDownload(res.data, 'Data Mitra.csv')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil download data mitra',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      }).then(() => {
        this.$refs.downloadBottomSheet.close()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.Mitra-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.bottom-sheet {
  padding: 16px 16px 200px 16px
}

.bottom-sheet-download {
  padding: 16px 16px 100px 16px
}

.badge-mitra{
  margin: 2px;
}
</style>

<style>
  .hitsList {
    list-style-type: none !important;
    padding-left: 0;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
